// @ts-nocheck
import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import { jumpLink } from '@/utils/url';
import EasyImage, { EasyImage3 } from '@components/EasyImage';
import { STATIC_AVATAR_IMG } from '@utils/const';
import './style.scss';

function navToComment() {
    jumpLink({
        url: '/comment',
        noBook: true,
    });
}

export default function Comment(props) {
    const { commentList = [] } = props;

    if (commentList.length <= 0) return null;

    return (
        <div className="m-comment-container">
            <div className="m-title">真实用户自驾评论</div>
            <ul className="m-main" onClick={navToComment}>
                {commentList.map(item => {
                    return (
                        <li className="m-list" key={item.cid}>
                            <EasyImage3
                                className="list-banner"
                                src={item.images[0]}
                                opt={{
                                    fw: 464,
                                    quality: 70,
                                }}
                            >
                                {item.imagesCount > 1 && (
                                    <div className="img-num">{item.imagesCount}图</div>
                                )}
                            </EasyImage3>

                            <div className="list-header">
                                <div className="left">
                                    <div className="avator">
                                        <EasyImage
                                            className="img"
                                            src={item.orderInfo?.user?.headImg}
                                            failbackSrc={
                                                STATIC_AVATAR_IMG[
                                                    Math.floor(
                                                        Math.random() *
                                                            STATIC_AVATAR_IMG.length
                                                    )
                                                ]
                                            }
                                        />
                                    </div>
                                    <div className="name">
                                        {item.orderInfo?.user?.driverName}
                                    </div>
                                    {item.isEasyRent && (
                                        <img
                                            className="easy-icon"
                                            src="https://imgcdn5.zuzuche.com/static/15/04/e3bfc73287a15f50c22925015220de2b.png"
                                            alt="easyRent"
                                        />
                                    )}
                                </div>
                                <div className="right-address">
                                    {item.orderInfo?.city}
                                </div>
                            </div>
                            <div className="list-content">{item.content}</div>
                            <div className="list-car">{item.orderInfo?.carName}</div>
                        </li>
                    );
                })}
                <li className="m-list-more">
                    <div className="more-text">查看更多</div>
                    <img
                        src="https://imgcdn5.zuzuche.com/static/16/72/68764e935580e1a24b5288bd8c7485e5.png"
                        alt="more"
                    />
                </li>
                <li className="border" />
            </ul>
        </div>
    );
}
